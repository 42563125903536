import { defineStore } from "pinia";
import axios from 'axios'
import { useRuntimeConfig } from "nuxt/app";
import { type IRegisterForm, type ILoginForm, type IVerifyAccountForm, type IForgetPasswordForm } from "../../types/account/authentication.types";
import helper from "~/resources/js/libraries/helper";
import { useRolesStore } from "./roles.store";

export const useAuthenticationStore = defineStore("authentication", {
	state: () => ({
		authModal: false
	}),
    getters: {
        authModalGetter: (state) => () => state.authModal
    },
	actions: {
        async login(form: ILoginForm) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            form['app_key'] = config.public.appKey as string;
            form['app_secret'] = config.public.appSecret as string;

            const appLocale = useNuxtApp().$i18n.locale.value || 'en'
            axios.defaults.headers['application-Language'] = appLocale
            axios.defaults.headers['application-platform'] = 'web';

            const rolesStore = useRolesStore()

			return await axios.post(`${accountApiUrl}/auth/login`, form)
                .then((res) => {
                    const token = res.data.token 
                    helper.set_cookie('vcrm-account-token', token, {
                        path: '/',
                        domain: config.public.cookieDomain,
                        secure: true,
                    });
                    rolesStore.fetchRoles()
                    return res.data
                }
            )
        },
        async register(form: IRegisterForm) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            form['app_key'] = config.public.appKey as string;
            form['app_secret'] = config.public.appSecret as string;

            const appLocale = useNuxtApp().$i18n.locale.value || 'en'
            axios.defaults.headers['application-Language'] = appLocale
            axios.defaults.headers['application-platform'] = 'web';

            return await axios.post(`${accountApiUrl}/auth/register`, form)
                .then((res) => {
                    return res.data
                }
            )
        },
        async verifyAccount(form: IVerifyAccountForm) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const appLocale = useNuxtApp().$i18n.locale.value || 'en'
            axios.defaults.headers['application-Language'] = appLocale
            axios.defaults.headers['application-platform'] = 'web';

            return await axios.post(`${accountApiUrl}/auth/activation-code`, form)
                .then((res) => {
                    return res.data
                }
            )
        },
        async forgotPassword(form: IForgetPasswordForm) {
            const config = useRuntimeConfig();
            const accountApiUrl = config.public.vcrmAccountApiUrl as string;

            const appLocale = useNuxtApp().$i18n.locale.value || 'en'
            axios.defaults.headers['application-Language'] = appLocale
            axios.defaults.headers['application-platform'] = 'web';

            return await axios.post(`${accountApiUrl}/auth/forgot-password`, form)
                .then((res) => {
                    return res.data
                }
            )
        },
        toggleAuthModal() {
            this.authModal = !this.authModal
        }
    }
});